<template>
  <div class="admin-fields">
    <h5 class="mb-4">{{ $t("admin-fields") }}</h5>
    <FormGenerator
      :elements="elements"
      :handleUpdate="handleUpdate"
      :data="book"
      inline
      v-bind="$attrs"
      :shouldCheckValidity="validate"
    />
  </div>
</template>

<script>
import FormGenerator from "@/components/form-generator";
// Fixtures
import BookStatuses from "@/fixtures/book-statuses";

export default {
  name: "admin-fields",
  components: {
    FormGenerator
  },
  props: {
    book: {
      type: Object,
      required: true
    },
    handleUpdate: {
      type: Function,
      required: true
    },
    validate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    elements() {
      return [
        {
          key: "fxl",
          label: "fxl",
          type: "switch"
        },
        {
          key: "rejected",
          label: "rejected",
          type: "switch"
        },
        {
          key: "commission_rate",
          label: "commission-rate",
          type: "number"
        },
        {
          key: "status",
          label: "status",
          type: "select",
          attrs: {
            options: BookStatuses.map(status => ({
              text: this.$t(status),
              value: status
            }))
          }
        },
        {
          key: "files_received",
          label: "Files Received",
          type: "switch"
        },
        {
          key: "notes",
          label: "notes",
          type: "text"
        }
      ];
    }
  }
};
</script>

<style></style>
